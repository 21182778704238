export const getComponentForContentType = (contentType, suffix) => {
  let component = 'image'

  if (contentType == 'application/pdf') {
    component = 'document'
  }

  if (contentType.startsWith('video/')) {
    component = 'video'
  }

  if (contentType.startsWith('audio/')) {
    component = 'audio'
  }

  return `ems-${component}-${suffix}`
}

export const isImage = media => {
  return media?.contentType.startsWith('image/')
}

export const imageUrlToDataUrl = (imageUrl) => {
  return new Promise((resolve, reject) => {
    // Fetch the image from the URL
    fetch(imageUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Image fetch failed');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a FileReader to read the Blob as a Data URL
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);  // This will be the Data URL
        };

        reader.onerror = (error) => {
          reject(error);
        };

        // Read the Blob as a Data URL
        reader.readAsDataURL(blob);
      })
      .catch(error => {
        reject(error);
      });
  });
}
